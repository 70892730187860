@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

.ag-cell {
    overflow: visible;
}

.ag-row {
    z-index: 0;
}

.ag-row.ag-row-focus {
    z-index: 1;
}

/* .ag-root.ag-layout-auto-height,
.ag-body-viewport.ag-layout-auto-height,
.ag-body-viewport-wrapper.ag-layout-auto-height {
    overflow: visible;
} */

.ag-root-wrapper { overflow: visible; } 
.fc-event{
    cursor: pointer;
}
.ag-cell-value,
.ag-cell {
    overflow: visible;
}
/* .ag-cell-wrap-text{
    line-height: 1.5 !important;
} */

.textEllipsis{
    text-overflow: ellipsis;
    overflow: hidden;
}

.grid-data > a{
    display: block;
    height: auto !important;
}

.ag-cell-focus, .ag-cell {
    border: none !important;
}
.ag-full-width-row.ag-row-focus:focus{
    border: none !important;
}
.ag-cell-focus {
    --ag-range-selection-border-color: transparent !important;
}



.color_1{
    background-color: #F53043 !important
}
.color_2{
    background-color: #FCA14E !important
}
.color_3{
    background-color: #FB8B25 !important
}
.color_4{
    background-color: #57BFFE !important
}
.color_5{
    background-color: #2F76E5 !important
}
.color_6{
    background-color: #44C150 !important
}
.color_7{
    background-color: #FF0000 !important
}

.btn-primary{
    background-color: #049DE3 !important;
}